import { ADD_SEARCH_RESULTS, REMOVE_ALL_REDUX } from '../Actions/searchResultsTypes'
import { SearchResultsState, SearchResult } from 'interfaces'

const initialState: SearchResultsState = {
    searchResults: [],
    updatedUnix: 0
}

interface AddSearchResultsAction {
    type: typeof ADD_SEARCH_RESULTS
    payload: SearchResult[]
}

interface RemoveAllReduxAction {
    type: typeof REMOVE_ALL_REDUX
}

type SearchResultsActions = AddSearchResultsAction | RemoveAllReduxAction

const searchResultsReducer = (
    state: SearchResultsState = initialState,
    action: SearchResultsActions
): SearchResultsState => {
    switch (action.type) {
        case ADD_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: action.payload,
                updatedUnix: Math.floor(Date.now() / 1000)
            }
        case REMOVE_ALL_REDUX:
            return initialState
        default:
            return state
    }
}

export default searchResultsReducer
