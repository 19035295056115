/* Header Design taken from https://flowbite.com/blocks/marketing/header/ */

import React, { useState } from 'react';
import { UserState } from 'interfaces';
import * as route from 'constants/routes';
import useAuthCookie from 'hooks/useAuthCookie';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'redux/Types';

const Header: React.FC = () => {
    const dispatch = useDispatch();
    const { removeAuthCookie, getAuthCookieValue } = useAuthCookie();
    const userState = useSelector((state: IRootState) => state.user) as UserState;
    const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

    const logout = async () => {
        removeAuthCookie();
        dispatch({
            type: 'REMOVE_ALL_REDUX',
        });

        console.log('---- User is now Logged Out ----');
        window.location.reload();
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    return (
        <header>
            <nav className="bg-gray-800 border-gray-200 px-4 lg:px-6 py-2.5">
                <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                    <Link to={route.INDEX} className="flex items-center">
                        <img
                            src="/logo/RegWatch_logo_500x500.png"
                            className="mr-3 h-6 sm:h-9"
                            alt="RegWatch Logo"
                        />
                        <span className="self-center text-xl font-semibold whitespace-nowrap text-white">
                            RegWatch
                        </span>
                    </Link>
                    <div className="flex items-center lg:order-2">
                        {!getAuthCookieValue() ? (
                            <Link
                                to={route.LOGIN}
                                className="text-white hover:bg-gray-700 focus:ring-4 focus:ring-gray-800 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
                            >
                                Log in
                            </Link>
                        ) : (
                            <button
                                onClick={logout}
                                className="text-white hover:bg-gray-700 focus:ring-4 focus:ring-gray-800 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none"
                            >
                                Log out
                            </button>
                        )}
                        <Link to={route.PROFILE}>
                            {userState.id !== '' ? (
                                <img
                                    className="w-10 h-10 rounded-full"
                                    src={userState.picture_url}
                                    alt="Rounded profile"
                                />
                            ) : (
                                <img
                                    className="w-10 h-10 rounded-full"
                                    src="images/profile-placeholder.jpg"
                                    alt="Rounded profile"
                                />
                            )}
                        </Link>
                        <button
                            onClick={toggleMobileMenu}
                            type="button"
                            className="inline-flex items-center p-2 ml-1 text-sm text-gray-400 rounded-lg lg:hidden hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-600"
                            aria-controls="mobile-menu-2"
                            aria-expanded={isMobileMenuOpen ? 'true' : 'false'}
                        >
                            <span className="sr-only">Open main menu</span>
                            <svg
                                className={`${isMobileMenuOpen ? 'hidden' : 'block'} w-6 h-6`}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                            <svg
                                className={`${isMobileMenuOpen ? 'block' : 'hidden'} w-6 h-6`}
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                ></path>
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`${
                            isMobileMenuOpen ? 'block' : 'hidden'
                        } justify-between items-center w-full lg:flex lg:w-auto lg:order-1`}
                        id="mobile-menu-2"
                    >
                        <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                            <li>
                                <Link
                                    to={route.INDEX}
                                    onClick={closeDropdown}
                                    className="block py-2 pr-4 pl-3 text-white rounded lg:bg-transparent lg:p-0"
                                    aria-current="page"
                                >
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={route.MARKETPLACE}
                                    onClick={closeDropdown}
                                    className="block py-2 pr-4 pl-3 text-white rounded lg:bg-transparent lg:p-0"
                                    aria-current="page"
                                >
                                    Marketplace
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to={route.DASHBOARD}
                                    onClick={closeDropdown}
                                    className="block py-2 pr-4 pl-3 text-white rounded lg:bg-transparent lg:p-0"
                                    aria-current="page"
                                >
                                    Dashboard
                                </Link>
                            </li>
                            {/* Mobile View Dropdown */}
                            <li className="relative lg:hidden block">
                            <button
                                    onClick={toggleDropdown}
                                    className="py-2 pr-4 pl-3 text-white rounded lg:bg-transparent lg:p-0 flex items-center"
                                >
                                    Tools
                                    <svg
                                        className={`ml-1 w-4 h-4 transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                {isDropdownOpen && (
                                    <div className="">
                                        <ul className="py-1">
                                            <li>
                                                <Link
                                                    to={route.DVLA_AUCTION_PRICE_CALCULATOR}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    DVLA Auction Price Calculator
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.GUIDE_TO_PLATES}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    Guide To Plates
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.PLATE_PRINTERS}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    Plate Printers
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.PLATE_CONTENT}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    Plate Content
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.OWNERSHIP_GUIDE}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    Ownership Guide
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.OTHER_PLATE_SITES}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 hover:text-gray-700 text-white block px-4 py-2"
                                                >
                                                    Other Plate Sites
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            
                            {/* Desktop View Dropdown */}
                            <li className="relative hidden lg:block">
                                <button
                                    onClick={toggleDropdown}
                                    className="py-2 pr-4 pl-3 text-white rounded lg:bg-transparent lg:p-0 flex items-center"
                                >
                                    Tools
                                    <svg
                                        className={`ml-1 w-4 h-4 transform ${isDropdownOpen ? 'rotate-180' : ''}`}
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                                        <ul className="py-1">
                                            <li>
                                                <Link
                                                    to={route.DVLA_AUCTION_PRICE_CALCULATOR}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    DVLA Auction Price Calculator
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.GUIDE_TO_PLATES}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    Guide To Plates
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.PLATE_PRINTERS}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    Plate Printers
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.PLATE_CONTENT}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    Plate Content
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.OWNERSHIP_GUIDE}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    Ownership Guide
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to={route.OTHER_PLATE_SITES}
                                                    onClick={closeDropdown}
                                                    className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2"
                                                >
                                                    Other Plate Sites
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
