import React from 'react'

const RedCross: React.FC = () => {
    return (
        <svg
            className="flex-shrink-0 w-5 h-5 text-red-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                d="M6.707 6.293a1 1 0 011.414 0L10 8.586l1.293-1.293a1 1 0 011.414 1.414L11.414 10l1.293 1.293a1 1 0 01-1.414 1.414L10 11.414l-1.293 1.293a1 1 0 01-1.414-1.414L8.586 10 7.293 8.707a1 1 0 010-1.414z"
                clipRule="evenodd"></path>
        </svg>
    )
}

const GreenTick: React.FC = () => {
    return (
        <svg
            className="flex-shrink-0 w-5 h-5 text-green-500"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clipRule="evenodd"></path>
        </svg>
    )
}

interface PricingCardProps {
    name: string
    notes: string
    cost: string
    individualConfig: boolean
    setupFees: boolean
    searchTerms: number
    searchesPerDay: number
    standardSites: boolean
    auctionSites: boolean
    premiumSites: boolean
}

const PricingCard: React.FC<PricingCardProps> = ({
    name,
    notes,
    cost,
    individualConfig,
    setupFees,
    searchTerms,
    searchesPerDay,
    standardSites,
    auctionSites,
    premiumSites
}) => {
    const RedOrGreen: React.FC<{ value: boolean }> = ({ value }) => {
        return value ? <GreenTick /> : <RedCross />
    }

    return (
        <div className="flex flex-col p-6 mx-auto max-w-lg text-center text-gray-900 bg-white rounded-lg border border-gray-100 shadow">
            <h3 className="mb-4 text-2xl font-semibold">{name}</h3>
            <p className="font-light text-gray-500 sm:text-lg">{notes}</p>
            <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-5xl font-extrabold">{cost}</span>
                <span className="text-gray-500">/month</span>
            </div>
            <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                    <RedOrGreen value={individualConfig} />
                    <span>Individual configuration</span>
                </li>
                <li className="flex items-center space-x-3">
                    <RedOrGreen value={setupFees} />
                    <span>No setup, or hidden fees</span>
                </li>
                <li className="flex items-center space-x-3">
                    <RedOrGreen value={standardSites} />
                    <span>Standard Sites</span>
                </li>
                <li className="flex items-center space-x-3">
                    <RedOrGreen value={auctionSites} />
                    <span>Auction Sites</span>
                </li>
                <li className="flex items-center space-x-3">
                    <RedOrGreen value={premiumSites} />
                    <span>Premium Sites</span>
                </li>
                <li className="flex items-center space-x-3">
                    Number of search terms:&nbsp;
                    <span className="font-semibold">{searchTerms}</span>
                </li>
                <li className="flex items-center space-x-3">
                    Number of whole site searches per day:&nbsp;
                    <span className="font-semibold">{searchesPerDay}</span>
                </li>
            </ul>
            <a
                href="/TODO"
                className="text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:ring-primary-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center">
                Get started
            </a>
        </div>
    )
}

const Pricing: React.FC = () => {
    return (
        <section className="bg-white">
            <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                        Pricing
                    </h2>
                    <p className="mb-5 font-light text-gray-500 sm:text-xl">
                        Unlock your personalised number plate journey at RegWatch with transparent
                        and competitive pricing options tailored to fit your style.
                    </p>
                </div>

                <div className="mb-5 space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                    <PricingCard
                        name={'Basic'}
                        notes={'Best option for personal use, on single searches.'}
                        cost={'Free'}
                        individualConfig={true}
                        setupFees={true}
                        searchTerms={1}
                        searchesPerDay={1}
                        standardSites={true}
                        auctionSites={false}
                        premiumSites={false}
                    />
                    <PricingCard
                        name={'Standard'}
                        notes={'Relevant for multiple search terms, and extended to auction sites.'}
                        cost={'£20'}
                        individualConfig={true}
                        setupFees={true}
                        searchTerms={5}
                        searchesPerDay={2}
                        standardSites={true}
                        auctionSites={true}
                        premiumSites={false}
                    />
                    <PricingCard
                        name={'Pro'}
                        notes={'Best for large set of search terms, and extended to premium sites.'}
                        cost={'£50'}
                        individualConfig={true}
                        setupFees={true}
                        searchTerms={20}
                        searchesPerDay={5}
                        standardSites={true}
                        auctionSites={true}
                        premiumSites={true}
                    />
                </div>
                <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
                    <p className="font-light text-gray-500 sm:text-xl">
                        For more pricing options please contact a member of our team.
                        {/* TODO: Add in contact us information */}
                    </p>
                </div>
            </div>
        </section>
    )
}

export default Pricing
