import { ADD_USER_INFO, REMOVE_ALL_REDUX } from '../Actions/userActionTypes'
import { UserState } from 'interfaces'

const initialState: UserState = {
    id: '',
    email: '',
    name: '',
    picture_url: '',
    subscription_level: ''
}

interface AddUserInfoAction {
    type: typeof ADD_USER_INFO
    payload: {
        id: string
        email: string
        name: string
        picture: string
        subscription_level: string
    }
}

interface RemoveAllReduxAction {
    type: typeof REMOVE_ALL_REDUX
}

type UserActions = AddUserInfoAction | RemoveAllReduxAction

const taskReducer = (state: UserState = initialState, action: UserActions): UserState => {
    switch (action.type) {
        case ADD_USER_INFO:
            return {
                ...state,
                id: action.payload.id,
                email: action.payload.email,
                name: action.payload.name,
                picture_url: action.payload.picture,
                subscription_level: action.payload.subscription_level
            }
        case REMOVE_ALL_REDUX:
            return initialState
        default:
            return state
    }
}

export default taskReducer
