import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import useAuthCookie from 'hooks/useAuthCookie'
import NumberPlate from 'components/NumberPlate'
import LoadSpinner from 'components/LoadSpinner'
import { SearchTerm } from 'interfaces'

interface TermRowProps {
    term: SearchTerm
}

const TermRow: React.FC<TermRowProps> = ({ term }) => {
    const dispatch = useDispatch()
    const { getAuthCookieValue } = useAuthCookie()
    const [isLoading, setIsLoading] = useState(false)

    const remove = async () => {
        const cookieValue = getAuthCookieValue()
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/search-terms/remove`
        const apiKey = process.env.REACT_APP_API_KEY

        try {
            setIsLoading(true)
            const response = await axios.post(
                apiUrl,
                {
                    searchTerm: term.search_term
                },
                {
                    headers: {
                        'X-API-Key': apiKey,
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookieValue}`
                    }
                }
            )

            if (response.status === 200) {
                dispatch({
                    type: 'REMOVE_SINGLE_SEARCH_TERM', // TODO Update to const value
                    payload: term.search_term
                })
            }

            setIsLoading(false)
            console.log('Search term remove API response: ', response)
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }

    return (
        <div className="border p-4 rounded-lg bg-white shadow-md max-w-full">
            <div className="flex items-center justify-between">
                <NumberPlate text={term.search_term} />
                {isLoading ? (
                    <LoadSpinner />
                ) : (
                    <button className="text-blue-500" onClick={remove} disabled={isLoading}>
                        Remove
                    </button>
                )}
            </div>
            <p className="mt-2 text-gray-600">{term.nickname || '-'}</p>
        </div>
    )
}

export default TermRow
