import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import useAuthCookie from 'hooks/useAuthCookie'
import NumberPlateInput from 'components/NumberPlate/Input'
import LoadSpinner from 'components/LoadSpinner'

interface FormData {
    numberPlate: string
    nickname: string
}

const InputRow: React.FC = () => {
    const dispatch = useDispatch()
    const { getAuthCookieValue } = useAuthCookie()
    const [formData, setFormData] = useState<FormData>({
        numberPlate: '',
        nickname: ''
    })
    const [apiError, setApiError] = useState<string>('')
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        if (!formData.numberPlate.trim()) {
            setApiError('Number plate is required')
            return
        }

        console.log('Search Term form Data:', formData)

        const cookieValue = getAuthCookieValue()
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/search-terms/add`
        const apiKey = process.env.REACT_APP_API_KEY

        try {
            setIsLoading(true)

            const response = await axios.post(
                apiUrl,
                {
                    searchTerm: formData.numberPlate.toLowerCase(),
                    searchTermNickname: formData.nickname || null
                },
                {
                    headers: {
                        'X-API-Key': apiKey,
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${cookieValue}`
                    }
                }
            )
            console.log('Search Term form API response:', response)

            if (response.status === 200) {
                dispatch({
                    type: 'ADD_SINGLE_SEARCH_TERM', // TODO Update to const value
                    payload: {
                        search_term: formData.numberPlate.toLowerCase(),
                        nickname: formData.nickname || null
                    }
                })
            }

            setIsLoading(false)
        } catch (error: unknown) {
            const defaultError = 'Error while adding search term'
            if (axios.isAxiosError(error)) {
                if (error.response?.status === 403) {
                    setApiError(error.response?.data.message || defaultError)
                    setIsLoading(false)
                    return
                }
            }
            setIsLoading(false)
        }

        setFormData({
            numberPlate: '',
            nickname: ''
        })
        // Clear the apiError message
        setApiError('')
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="border p-4 rounded-lg bg-white shadow-md">
                <div className="flex items-center justify-between">
                    {/* <input
                        type="text"
                        className="border border-gray-300 p-2 rounded-md"
                        placeholder="Number Plate"
                        name="numberPlate"
                        value={formData.numberPlate}
                        onChange={handleChange}
                    /> */}
                    <NumberPlateInput
                        placeholder="Number Plate"
                        name="numberPlate"
                        value={formData.numberPlate}
                        onChange={handleChange}
                    />
                    {isLoading ? (
                        <LoadSpinner />
                    ) : (
                        <button type="submit" className="text-blue-500 ml-2" disabled={isLoading}>
                            Add Search Term
                        </button>
                    )}
                </div>
                {apiError && <p className="mt-2 text-red-500">{apiError}</p>}
                <input
                    type="text"
                    className="mt-2 border border-gray-300 p-2 rounded-md w-full"
                    placeholder="Number plate nickname"
                    name="nickname"
                    value={formData.nickname}
                    onChange={handleChange}
                />
            </div>
        </form>
    )
}

export default InputRow
