export const INDEX = '/'
export const FREQUENTLY_ASKED_QUESTIONS = 'faqs'
export const MARKETPLACE = 'marketplace'

// Auth
export const LOGIN = 'login'
export const LOGIN_CALLBACK = 'login-callback'

// Policy Pages
export const POLICY_PAGE = 'policy-page'
export const TERMS_OF_USE = 'terms-of-use'

// Logged in routes
export const PROFILE = 'profile'
export const DASHBOARD = 'dashboard'

// Other
export const DVLA_AUCTION_PRICE_CALCULATOR = 'tools/dvla-auction-price-calculator'
export const GUIDE_TO_PLATES = 'tools/guide-to-plates'
export const PLATE_PRINTERS = 'tools/plate-printers'
export const OTHER_PLATE_SITES = 'tools/other-plate-sites'
export const PLATE_CONTENT = 'tools/plate-content'
export const OWNERSHIP_GUIDE = 'tool/plate-ownership-guide'
export const TEST = 'test-page'
export const TODO = 'TODO'
