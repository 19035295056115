import React from 'react'

const TestPage: React.FC = () => {
    const handleClick = () => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/test/hello-world-copy`
        const apiKey = process.env.REACT_APP_API_KEY || 'FULL-BACK'

        // Fetch API
        fetch(apiUrl, {
            headers: {
                'X-API-Key': apiKey,
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok')
                }
                return response.json()
            })
            .then((data) => {
                console.log('API Response:', data)
            })
            .catch((error) => {
                console.error('Error fetching API:', error)
            })
    }

    return (
        <div>
            <h1>Test Page</h1>
            <button onClick={handleClick}>Fetch API Test API</button>
        </div>
    )
}

export default TestPage
